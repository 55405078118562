import {
    Behaviour,
    behaviours,
} from '../../../global/scripts/behaviours/behaviour'
import jQuery from 'jquery'

const CheckboxRolesBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        context
            .querySelectorAll<HTMLElement>('.gfield.roles')
            .forEach((field) => init(field))
    }

    const init = function (field: HTMLElement) {
        // Only run once per gfield
        if (field.classList.contains('behaviourAttached')) {
            return
        }
        field.classList.add('behaviourAttached')

        const legend = field.querySelector('legend')
        if (legend) {
            legend.addEventListener('click', () => {
                const isOpen = field.classList.toggle('open')
                if (!isOpen) {
                    field
                        .querySelectorAll<HTMLInputElement>(
                            'input[type=checkbox]'
                        )
                        .forEach((checkbox) => {
                            checkbox.checked = false
                        })
                }
            })
            if (field.querySelector('input[type=checkbox]:checked')) {
                field.classList.add('open')
            }
        }
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(CheckboxRolesBehaviour)
;(function ($) {
    $(document).on('gform_post_render', (e) => {
        CheckboxRolesBehaviour.attach(e.target.documentElement)
    })
})(jQuery)
