function hasTouch() {
    return (
        'ontouchstart' in document.documentElement ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    )
}

const setVars = () => {
    const vh = window.innerHeight * 0.01
    const vw = document.body.clientWidth * 0.01
    document.body.style.setProperty('--vh', `${vh}px`)
    document.body.style.setProperty('--vw', `${vw}px`)
    document.body.classList.toggle('deviceHasTouch', hasTouch())
    document.body.classList.toggle('deviceNoTouch', !hasTouch())
}

window.addEventListener('load', setVars)
window.addEventListener('resize', setVars)
