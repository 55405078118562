import {
    Behaviour,
    behaviours,
} from '../../../global/scripts/behaviours/behaviour'

const JsLinkBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const links = context.querySelectorAll<HTMLElement>('.jsLink')

        if (links.length === 0) {
            return
        }

        links.forEach((link) => init(link))
    }

    const init = function (link: HTMLElement) {
        const href = link.getAttribute('data-href') ?? ''
        const target = link.getAttribute('data-target')
        link.addEventListener('click', () => {
            if (target && target == '_blank') {
                window.open(href)
            } else {
                window.location.href = href
            }
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(JsLinkBehaviour)
