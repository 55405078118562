import {behaviours} from '../behaviours/behaviour'

behaviours.addBehaviour(
    (() => {
        const capitalizeFirstLetter = (string) =>
            string.charAt(0).toUpperCase() + string.slice(1)

        const getAttributeForBlock = function (block, attribute) {
            if (!block) {
                return null
            }
            if (!block.hasAttribute(attribute)) {
                return null
            }
            return block.getAttribute(attribute)
        }

        const getBackgroundForBlock = function (block) {
            const background = getAttributeForBlock(block, 'data-background')
            return background && background !== 'none' ? background : false
        }

        const attach = function () {
            document
                .querySelectorAll('div[data-background]')
                .forEach((block) => {
                    const background = block.getAttribute('data-background')
                    const previousBlock = block.previousElementSibling

                    const blockPullBackground = getAttributeForBlock(
                        block,
                        'data-pull-background'
                    )
                    const nextBlockBackground = getBackgroundForBlock(
                        block.nextElementSibling
                    )
                    const previousBlockBackground = getBackgroundForBlock(
                        block.previousElementSibling
                    )

                    if (
                        (blockPullBackground === 'bottom' ||
                            blockPullBackground === 'both') &&
                        nextBlockBackground
                    ) {
                        block.classList.add(
                            `${block.classList[0]}--backgroundBottom${capitalizeFirstLetter(nextBlockBackground)}`
                        )
                        const nextBlock = block.nextElementSibling

                        nextBlock.classList.contains('withBgImage')
                            ? block.classList.add(
                                  `${block.classList[0]}--nextBlockHasBackgroundImage`
                              )
                            : null
                    }

                    if (
                        (blockPullBackground === 'top' ||
                            blockPullBackground === 'both') &&
                        previousBlockBackground
                    ) {
                        //if previous block has image as background set height to 150% of previous block
                        if (previousBlockBackground === 'image') {
                            const previousBlockBackground =
                                previousBlock.querySelector(
                                    `.${previousBlock.classList[0]}__background`
                                )

                            //delay to get height of block
                            setTimeout(() => {
                                const blockHeight = block.clientHeight
                                const previousBlockHeight =
                                    previousBlock.clientHeight

                                if (previousBlockBackground) {
                                    previousBlockBackground.style.height =
                                        previousBlockHeight +
                                        blockHeight / 2 +
                                        'px'
                                    previousBlockBackground.style.zIndex = '4'
                                }
                            }, 100)
                        } else {
                            block.classList.add(
                                `${block.classList[0]}--backgroundTop${capitalizeFirstLetter(previousBlockBackground)}`
                            )
                        }
                    }

                    if (!previousBlockBackground) {
                        return
                    }

                    if (
                        previousBlock.getAttribute('data-background') ===
                            background &&
                        previousBlock.classList.contains(block.classList[0])
                    ) {
                        previousBlock.classList.add(
                            `${previousBlock.classList[0]}--nextBlockSameBackgroundSameType`
                        )
                        previousBlock.setAttribute(
                            'data-next-block',
                            block.classList[0]
                        )
                    } else if (
                        previousBlock.getAttribute('data-background') ===
                        background
                    ) {
                        previousBlock.classList.add(
                            `${previousBlock.classList[0]}--nextBlockSameBackground`
                        )
                        previousBlock.setAttribute(
                            'data-next-block',
                            block.classList[0]
                        )
                    }
                })
        }

        return {
            attach: attach,
        }
    })()
)
