import {behaviours} from '../behaviours/behaviour'

const GutenbergColorPickerBehaviour = (function () {
    const attach = function () {
        const colorPicker = document.querySelector(
            '#poststuff .acf-color-picker input'
        )
        if (
            colorPicker &&
            !colorPicker.classList.contains('processedByVollan')
        ) {
            let previousValue = ''
            colorPicker.classList.add('processedByVollan')
            setInterval(() => {
                if (colorPicker.value !== previousValue) {
                    previousValue = colorPicker.value
                    document
                        .querySelectorAll(
                            '.block-editor-block-list__layout .acf-block-preview'
                        )
                        .forEach((block) => {
                            block.style.background = previousValue
                        })
                }
            }, 1000)
        }
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(GutenbergColorPickerBehaviour)
