import InView from 'inview'
import {behaviours} from '../../../global/scripts/behaviours/behaviour'

const animationBehaviour = (function () {
    const attach = function (context) {
        const animationParents = context.querySelectorAll('.animateParent')
        animationParents.forEach((animationParent) => {
            InView(animationParent, function (isInView, data) {
                if (
                    data.elementOffsetTopInViewHeight <
                    data.inViewHeight * 0.9
                ) {
                    animationParent.classList.add('animateParent--started')
                    this.destroy()
                }
            })
        })
    }

    return {
        attach: attach,
    }
})()
behaviours.addBehaviour(animationBehaviour)
