import {behaviours} from '../../../global/scripts/behaviours/behaviour'

import {SwiperModule} from 'swiper/types'

const blockProjectSliderBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.baseSliderBlock')

        if (blocks.length === 0) {
            return
        }

        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, module))
        })
    }

    const init = function (
        block: HTMLElement,
        module: typeof import('swiper')
    ) {
        const Swiper = module.default
        const NavigationModule = module.Navigation as SwiperModule

        const baseClass = 'baseSliderBlock'
        const sliderElement = block.querySelector<HTMLElement>(
            `.${baseClass}__slider`
        )
        const wrapperClass = `${baseClass}__items`
        const slideClass = `${baseClass}__item`
        const slidesPerViewDesktopLarge = block.hasAttribute(
            'data-slides-per-view'
        )
            ? parseInt(block.getAttribute('data-slides-per-view') as string)
            : 'auto'
        const sliderType = block?.getAttribute('data-slider-type')
        const sliderOnMobile = block.hasAttribute('data-slider-on-mobile')
            ? true
            : false
        const slidesPerViewMobile = 1.1
        let slidesPerViewTablet = 2
        let slidesPerViewDesktop = 3
        const spaceBetweenDesktop = 32
        const spaceBetweenTablet = 25

        const simulateTouch = true

        const navClass = 'slideNavigation__item'
        const buttonNext = block?.querySelector<HTMLElement>(
            `.${navClass}--next`
        )
        const buttonPrev = block?.querySelector<HTMLElement>(
            `.${navClass}--previous`
        )

        if (!sliderElement) {
            return false
        }

        if (sliderType == 'certificates') {
            slidesPerViewTablet = 2
            slidesPerViewDesktop = 3
        }

        if (sliderType == 'teasers') {
            slidesPerViewTablet = 1
            slidesPerViewDesktop = 2
        }

        const swiper = new Swiper(sliderElement, {
            modules: [NavigationModule],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: slidesPerViewMobile,
            spaceBetween: 20,
            slideActiveClass: `${baseClass}__item--active`,
            slideVisibleClass: `${baseClass}__item--visible`,
            watchSlidesProgress: true,
            threshold: 5,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                768: {
                    spaceBetween: spaceBetweenTablet,
                    slidesPerView: slidesPerViewTablet,
                },
                1024: {
                    spaceBetween: spaceBetweenDesktop,
                    slidesPerView: slidesPerViewDesktop,
                    simulateTouch: simulateTouch,
                },
                1366: {
                    spaceBetween: spaceBetweenDesktop,
                    slidesPerView: slidesPerViewDesktopLarge,
                    simulateTouch: simulateTouch,
                },
            },
        })

        // if sliderOnMobile is false destroy slider on mobile
        if (!sliderOnMobile) {
            if (window.innerWidth <= 767) {
                if (swiper) {
                    swiper.destroy()
                }
            }
        }
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(blockProjectSliderBehaviour)
