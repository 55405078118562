import {behaviours} from '../../../global/scripts/behaviours/behaviour'
import gsap from 'gsap'

const factCounterBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const fact = context.querySelectorAll<HTMLElement>('.fact')

        if (fact.length === 0) {
            return
        }

        fact.forEach((fact) => init(fact))
    }

    const animateElement = (
        element: HTMLElement | null,
        properties: gsap.TweenVars,
        trigger: HTMLElement | null
    ) => {
        if (element) {
            // gsap.to(element, {...properties, scrollTrigger: {trigger, scrub: true}})
            gsap.from(element, {
                ...properties,
                duration: 2,
                snap: {textContent: 1},
                scrollTrigger: {trigger, scrub: false},
                stagger: 1,
                // onUpdate: textContent.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            })
        }
    }

    const init = function (fact: HTMLElement) {
        const baseClass = fact.classList[0]

        const countElmt = fact.querySelector<HTMLElement>(
            `.${baseClass}__value`
        )

        if (!countElmt) {
            return
        }

        animateElement(countElmt, {textContent: 0}, countElmt)
    }

    return {
        attach,
    }
})()

behaviours.addBehaviour(factCounterBehaviour)
