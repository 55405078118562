import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {behaviours} from '../behaviours/behaviour'

gsap.registerPlugin(ScrollTrigger)

const parallaxBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const parallaxBlocks =
            context.querySelectorAll<HTMLDivElement>('.parallax')

        if (parallaxBlocks.length === 0) {
            return
        }

        parallaxBlocks.forEach((block) => init(block))
    }

    const animateElement = (
        element: HTMLElement | null,
        properties: gsap.TweenVars,
        trigger: HTMLElement | null
    ) => {
        if (element) {
            gsap.to(element, {
                ...properties,
                scrollTrigger: {trigger, scrub: true},
            })
        }
    }

    const init = function (block: HTMLElement) {
        const baseClass = block.classList[0]

        const parallaxElmt = block.querySelector<HTMLElement>(
            `.${baseClass}__parallaxElmt`
        )

        if (!parallaxElmt) {
            return
        }

        const isMobile = window.innerWidth < 768
        const xValue = isMobile ? 40 : 180
        const yValue = isMobile ? 40 : 180

        if (parallaxElmt.classList.contains('parallaxElmtRight')) {
            animateElement(parallaxElmt, {x: -xValue}, parallaxElmt)
        } else if (parallaxElmt.classList.contains('parallaxElmtLeft')) {
            animateElement(parallaxElmt, {x: xValue}, parallaxElmt)
        } else if (parallaxElmt.classList.contains('parallaxElmtDown')) {
            animateElement(parallaxElmt, {y: yValue}, parallaxElmt)
        } else if (parallaxElmt.classList.contains('parallaxElmtUp')) {
            animateElement(parallaxElmt, {y: -yValue}, parallaxElmt)
        }
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(parallaxBehaviour)
